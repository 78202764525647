import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { StorageServiceModule, LOCAL_STORAGE } from 'ngx-webstorage-service';
import { Router, ActivatedRoute } from '@angular/router';

import { AutenticacaoService } from 'src/app/services/autenticacao.service';
import { WebserviceLogin } from '../models/webservice-login';
import { from } from 'rxjs';

import { ToastrService } from 'ngx-toastr';
import * as jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-page-login',
  templateUrl: './page-login.component.html',
  styleUrls: ['./page-login.component.scss']
})
export class PageLoginComponent implements OnInit {
  loading = false;
  form: FormGroup;
  return: string = '';

  constructor(private _fb: FormBuilder,
    private _autenticacaoService: AutenticacaoService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute) {
    this.createForm();
  }

  ngOnInit(): void {
    this.route.queryParams
    .subscribe(params => this.return = params['return'] || '/');
  }

  createForm() {
    this.form = this._fb.group({
      login: [null, [Validators.required]],
      senha: [null, [Validators.required]]
    })
  }

  async login() {
    const data = this.form.value
    this.loading = true;
    let model: WebserviceLogin = {
      login: data.login,
      senha: data.senha
    };

    this._autenticacaoService.login(model)
      .subscribe(
        (response) => {
          let token = jwt_decode(response.data);
          localStorage.setItem('token', response.data);
          this.loading = false;
          this.router.navigateByUrl(this.return);
        },
        (erro) => {
          
          if (erro.status == 400) {
            this.toastr.error(erro.error.message, '');
          } else if (erro.status == 401) {
            localStorage.setItem('token', null);
            window.location.reload();
          }
          else {
            this.toastr.error('Erro ao efetuar o Login', '');
          }
          this.loading = false;
        }
      );

  }

}
