import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { StorageServiceModule, LOCAL_STORAGE } from 'ngx-webstorage-service';

import { AutenticacaoService } from 'src/app/services/autenticacao.service';
import { WebserviceLogin } from '../models/webservice-login';
import { from } from 'rxjs';
import { AbertoService } from '../services/aberto.service';
import { SegmentosAberto } from '../models/segmentos-aberto';
import { ClienteSegmento } from '../models/cliente-segmento';

import { ToastrService } from 'ngx-toastr';
import { typeWithParameters } from '@angular/compiler/src/render3/util';

@Component({
  selector: 'app-page-clientes-aberto',
  templateUrl: './page-clientes-aberto.component.html',
  styleUrls: ['./page-clientes-aberto.component.scss']
})
export class PageClientesAbertoComponent implements OnInit {

  loading = false;
  form: FormGroup;
  segmentosAberto: SegmentosAberto[];
  segmentosCliente: any[];

  constructor(private _fb: FormBuilder,
    private _abertoService: AbertoService,
    private _toastr: ToastrService) {
    this.createForm();

  }

  ngOnInit(): void {
    this.buscarSegmentos();
   
  }

  createForm() {
    this.form = this._fb.group({
       cpf: [null, [Validators.required]],
       segmento: [null, [Validators.required]]
    })
  }

  buscarSegmentos() {
    this._abertoService.buscarTodosSegmentos()
      .subscribe(
        (response) =>{
          this.segmentosAberto = response.data;
          
        },
        (erro) =>{
          console.log(erro.message);
        }
      )
  }

  enviarCliente(){

    this.loading = true;
    let clienteSegmento: ClienteSegmento;
    clienteSegmento = new ClienteSegmento();
    clienteSegmento.cpf = this.form.value.cpf;
    clienteSegmento.segmentoId = this.form.value.segmento;

    this._abertoService.enviarClienteParaSegmento(clienteSegmento)
    .subscribe(
      (response) =>{
        console.log(response.data);
        this.createForm();
        this.loading = false;
        this._toastr.success('Cliente adicionado com sucesso','');
      },
      (erro) =>{
        this.loading = false;
        if (erro.status == 400) {
          this._toastr.error(erro.error.message, '');
        } else if (erro.status == 401) {
          localStorage.setItem('token', null);
          window.location.reload();
        }
        else {
          this._toastr.error('Erro ao enviar cliente para promoção', '');
        }
        this.loading = false;
      }
    )
  }

  consultarCliente(){
    this.loading=true;
    const cpf = this.form.value.cpf;
    this._abertoService.buscarSegmentoCliente(cpf)
      .subscribe((response)=>{
        this.segmentosCliente = response.data;
        this.loading=false;
      },
      (erro)=>{
        this.loading = false;
        if (erro.status == 400) {
          this._toastr.error(erro.error.message, '');
        } else if (erro.status == 401) {
          localStorage.setItem('token', null);
          window.location.reload();
        }
        else {
          this._toastr.error('Erro ao consultar cliente', '');
        }
        this.loading = false;        
      })
  }

}
