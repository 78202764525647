<div class="flex height-max flex-direction-column background-color-cinza ">
    <ngx-loading [show]="loading"></ngx-loading>
    <!-- HEADER -->
    <app-header></app-header>

    <div class="flex flex-direction-row margin-container padding-padrao ">
        <span class="span span-roxo span-titulo">
            CONSULTA EXTRATO CLIENTE
        </span>
    </div>


    <!-- DIVISAO -->
    <div class="flex flex-direction-row margin-container divisao ">
    </div>




    <div class="flex flex-direction-row margin-container padding-padrao flex-justify-content-center ">
      <form [formGroup]="form">
            <span class="span span-roxo margin-separar-frente">
                CPF
            </span>
            <input class="input input-roxo width-cpf margin-separar-frente"
                   type="text" 
                   mask="000.000.000-00" 
                   formControlName="cpf"
                   placeholder="000.000.000-00" >

            <span class="span span-roxo margin-separar-ambos">
                DATA INICIO
            </span>
            <input class ="input input-roxo margin-separar-frente" [(ngModel)]="dtInicio"
            type="date"
            formControlName="dtInicio">
            <span class="span span-roxo margin-separar-ambos">
                DATA FINAL
            </span>
            <input class ="input input-roxo margin-separar-frente" [(ngModel)]="dtFinal"
            type="date"
            formControlName="dtFinal">            
            

            <button 
                class="margin-separar-tras botao botao-roxo height-100 " 
                (click)="consultaExtrato()"
                >
                
                CONSULTAR
            </button>
    
            
        </form>        
    </div>

    <div class="container-tbl">
        <div class="row">
            <div class="col-md">
                Nome Cliente: <strong> {{nomeCliente}} </strong>
            </div>
            <div class="col-md">
                Documento : <strong> {{documento}} </strong>
            </div>
            <div class="col-md">
                Saldo : <strong>R$ {{saldoCliente}} </strong>
            </div>
        </div>
        <div class="row tbl-header">
            <div class="col-md">
                <span>Data</span>
            </div>
            <div class="col-md">
                <span>Descrição</span>
            </div>
            <div class="col-md">
                <span>Valor</span>
            </div>
            <div class="col-md">
                <span>Tipo</span>
            </div>
        </div>
        <hr class="header"/>
        <div class="container-extrato">
            <div *ngFor="let item of extrato">
                <div class="row tbl-col {{item.tipo==1?'credito':'debito'}}">
                    <div class="col-md">
                        <span>{{item.data | date:'dd/MM/yyyy HH:mm':'UTC'}}</span>
                    </div>
                    <div class="col-md">
                        <span >{{item.descricao}}</span>
                    </div>
                    <div class="col-md">
                        <span> <strong>R$ {{item.valor}}</strong></span>
                    </div>
                    <div class="col-md">
                        <span>{{item.tipo==1?"Credito":"Debito"}}</span>
                    </div>
                </div>                                  
            <hr>
            </div>
        </div>

    </div>


</div>

