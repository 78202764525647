import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AbertoService } from '../services/aberto.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-page-cliente-extrato',
  templateUrl: './page-cliente-extrato.component.html',
  styleUrls: ['./page-cliente-extrato.component.scss']
})
export class PageClienteExtratoComponent implements OnInit {
  loading = false;
  form: FormGroup;
  extrato:any[];
  dtInicio: any;
  dtFinal: any;
  nomeCliente:any;
  saldoCliente:any;
  documento:any;

  constructor(private _fb: FormBuilder,
    private _abertoService: AbertoService,
    private _toastr: ToastrService
    ) { 
    this.createForm();
    

  }

  ngOnInit(): void {
    var date = new Date();
    let dtInicio=new Date(date.getFullYear(), date.getMonth(), 1);
    let dtFinal=new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.dtInicio = dtInicio.toJSON().slice(0,10);
    this.dtFinal = dtFinal.toJSON().slice(0,10);
    console.log(this.dtInicio);
  }

  createForm() {
    this.form = this._fb.group({
       cpf: [null, [Validators.required]],
       dtInicio: [null, [Validators.required]],
       dtFinal: [null, [Validators.required]]
    })
  }

  consultaExtrato(){
    if(!this.form.get('cpf').valid || !this.form.get('dtInicio').valid|| !this.form.get('dtFinal').valid){
      this._toastr.error('Para consultar extrato precisa CPF e o periodo ', '');
      return;
    }

this.loading=true;

    this._abertoService.buscarExtratoCliente(this.form.value.cpf,this.form.value.dtInicio,this.form.value.dtFinal)
    .subscribe((response)=>{
      this.extrato = response.data.extrato;
      this.nomeCliente = response.data.nome;
      this.saldoCliente = response.data.saldo;
      this.documento = response.data.documento;
      this.loading=false;
    },
    (erro)=>{
      this._toastr.error('Erro ao consulta o extrato do cliente ', '');
      this.loading=false;
    });

  }

}
