import { Injectable } from '@angular/core';
import { WebserviceLogin } from '../models/webservice-login';
import { SegmentosAberto } from '../models/segmentos-aberto';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponseBase } from '../models/response-base';
import { ClienteSegmento } from '../models/cliente-segmento';

@Injectable({
    providedIn: 'root'
})
export class AbertoService {
    
    constructor(private _http: HttpClient) {
      
    }

    buscarTodosSegmentos(): Observable<ResponseBase<SegmentosAberto[]>>{
        let headerRequisicao = new HttpHeaders();
        headerRequisicao = headerRequisicao.set('Authorization',`Bearer ${localStorage.getItem('token')}`)
        
        return this._http.get<ResponseBase<SegmentosAberto[]>>(environment.baseUrl + '/Aberto/Segmentos',{headers: headerRequisicao});
    }

    enviarClienteParaSegmento(data: ClienteSegmento): Observable<ResponseBase<string>>{
        let headerRequisicao = new HttpHeaders();
        headerRequisicao = headerRequisicao.set('Authorization',`Bearer ${localStorage.getItem('token')}`)
        
        return this._http.post<ResponseBase<string>>(environment.baseUrl + '/Aberto/AdicionarClienteAoSegmento',data,{headers: headerRequisicao});
    }

    buscarExtratoCliente(cpf:string,dtInicio:string,dtFinal:string): Observable<ResponseBase<any>>{
        let headerRequisicao = new HttpHeaders();
        headerRequisicao = headerRequisicao.set('Authorization',`Bearer ${localStorage.getItem('token')}`)
        
        return this._http.get<ResponseBase<any>>(environment.baseUrl+'/dashboard/extrato/'+cpf+'?dtInicio='+dtInicio+'&dtFinal='+dtFinal,{headers:headerRequisicao})
    }

    buscarSegmentoCliente(cpf:string):Observable<ResponseBase<any>>{
        let headerRequisicao = new HttpHeaders();
        headerRequisicao = headerRequisicao.set('Authorization',`Bearer ${localStorage.getItem('token')}`)
        
        return this._http.get<ResponseBase<any>>(environment.baseUrl+"/dashboard/segmento/"+cpf,{headers:headerRequisicao});
    }
}