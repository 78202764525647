import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import * as jwt_decode from 'jwt-decode';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  role:string='';

  constructor() { }

  ngOnInit(): void {
    let decoded = jwt_decode(localStorage.getItem('token'));
    this.role = decoded.role;
  }
  logout(){
    localStorage.setItem('token',null);
    window.location.reload();
  }
}
