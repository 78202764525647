<nav class="navbar navbar-expand-lg navbar-light " style="background-color:white;">
    
    
    <a class="navbar-brand" href="#">
      <img src="assets\images\logo.svg" height="50px" alt="">
    </a>

    

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item "  [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}" *ngIf="role=='admin'">
        <a class="nav-link" [routerLink]="['/cliente-segmento']">Cliente Segmento <span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item"  [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}" *ngIf="role=='admin'">
        <a class="nav-link" [routerLink]="['/dados-cliente']">Atualizar Cadastro</a>
      </li>
      <li class="nav-item"  [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}" *ngIf="role=='admin'|| role =='operador'">
        <a class="nav-link" [routerLink]="['/cliente-extrato']">Extrato Cliente</a>
      </li>    
    </ul>
    <form class="form-inline my-2 my-lg-0">
      
      <button class="botao botao-amarelo" style="width:100px;"  (click)="logout()" >SAIR</button>
    </form>
  </div>
     

    
     
</nav>