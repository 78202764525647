<div class="flex height-max flex-direction-column background-color-cinza ">
    <ngx-loading [show]="loading"></ngx-loading>
    <!-- HEADER -->
    <app-header></app-header>

    <div class="flex flex-direction-row margin-container padding-padrao ">
        <span class="span span-roxo span-titulo">
            INSERIR CLIENTES EM PROMOÇÃO
        </span>
    </div>
    <div class="flex flex-direction-row margin-container padding-padrao ">
       
        <form [formGroup]="form">
            <span class="span span-roxo margin-separar-frente">
                CPF
            </span>
            <input class="input input-roxo width-cpf margin-separar-frente"
                   type="text" 
                   mask="000.000.000-00" 
                   formControlName="cpf"
                   placeholder="000.000.000-00" >

            <span class="span span-roxo margin-separar-ambos">
                SEGMENTO
            </span>

            <select 
                class="margin-separar-frente dropdown dropdown-roxo"
                formControlName="segmento">
                <option *ngFor="let seg of segmentosAberto" [value]="seg.idSegmento">{{seg.nomeSegmento}}</option>
            </select>

            <button 
                class="margin-separar-tras botao botao-roxo height-100 "
                (click)="enviarCliente()">
                ADICIONAR CLIENTE
            </button>
            <button 
            class="margin-separar-tras botao botao-roxo height-100 "
            (click)="consultarCliente()">
            CONSULTAR CLIENTE
        </button>
    
            
        </form>
    </div>

    <!-- DIVISAO -->
    <div class="flex flex-direction-row margin-container divisao ">
    </div>
    <br>
    <div class="container-tbl">
        <div class="row tbl-header">
            <div class="col-md">
                <span>Segmento</span>
            </div>
            <div class="col-md">
                <span>Id</span>
            </div>
            <div class="col-md">
                <span>Dt. Cadastro</span>
            </div>            
        </div>
        <hr class="header"/>
        <div class="row tbl-col" *ngFor="let item of segmentosCliente">
            <div class="col-md">
                <span>{{item.name}}</span>
            </div>
            <div class="col-md segmento-id">
                <span>{{item.id}}</span>
            </div>
            <div class="col-md">
                <span>{{item.insertionDate | date:'dd/MM/yyyy'}}</span>
            </div>
        </div>
    </div>

</div>