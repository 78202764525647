<div class="flex height-max flex-direction-column background-color-cinza ">
    
    <!-- HEADER -->
    <app-header></app-header>

    <div class="flex flex-direction-row margin-container padding-padrao ">
        <span class="span span-roxo span-titulo">
            CRM BACKOFFICE
        </span>
    </div>


    <!-- DIVISAO -->
    <div class="flex flex-direction-row margin-container divisao ">
    </div>