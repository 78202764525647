<div class="flex flex-align-itens-center flex-justify-content-center height-max background-color-roxo">
    <ngx-loading [show]="loading"></ngx-loading>

    <form [formGroup]="form" class="flex flex-direction-column flex-align-itens-center">
        <div class="flex flex-direction-row padding-semi-grande">
            <img src="assets\images\logo.svg" alt="Logo">
        </div>
        <div class="flex flex-direction-row padding-pequetuxo width-max flex-justify-content-center">
            <input class="flex flex-basis-80 width-max padding-pequetuxo input input-roxo input-text-bold" 
                   type="text"
                   formControlName="login" 
                   placeholder="USUÁRIO">
        </div>
        <div class=" flex flex-direction-row padding-pequetuxo width-max flex-justify-content-center">
            <input class="flex flex-basis-80 width-max padding-pequetuxo input input-roxo input-text-bold"
                   type="password" 
                   formControlName="senha" 
                   placeholder="SENHA">
        </div>
        <div class="flex flex-direction-row padding-padrao width-max flex-justify-content-center">
            <button
                class="flex flex-basis-84 width-max flex-justify-content-center flex-align-itens-center botao botao-amarelo"
                (click)="login()">
                ENTRAR
            </button>
        </div>
    </form>
</div>