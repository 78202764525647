import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ClienteService } from '../services/cliente.service';
import { Cliente } from '../models/cliente.model';
import { ResponseMeusolzinho } from '../models/response-meusolzinho.model';
import { ResponseBaseMeusolzinho } from '../models/response-base-meusolzinho.model';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-page-dados-cliente',
  templateUrl: './page-dados-cliente.component.html',
  styleUrls: ['./page-dados-cliente.component.scss'],
})
export class PageDadosClienteComponent implements OnInit {
  loading = false;
  formDados: FormGroup;
  formUpload: FormGroup;
  public modalRef: BsModalRef;
  cpfCliente: String;
  nomeCliente: String;
  sobreNomeCliente: String;
  dddCliente: String;
  telefoneCliente: String;
  emailCliente: String;
  logEnvio: ResponseMeusolzinho<ResponseBaseMeusolzinho>;
  block: boolean;

  constructor(
    private _fb: FormBuilder,
    private _clienteService: ClienteService,
    private _toastr: ToastrService,
    private modalService: BsModalService
  ) {
    this.formDados = this._fb.group({
      cpf: [null, [Validators.required]],
      nome: [null, [Validators.required]],
      sobrenome: [null, [Validators.required]],
      ddd: [null, [Validators.required]],
      telefone: [null, [Validators.required]],
      email: [null, [Validators.required]],
    });

    this.formUpload = this._fb.group({ file: [''] });

    this.blockForm(true);
  }

  ngOnInit(): void {}

  public openModal(template: TemplateRef<any>) {
    this.logEnvio = null;
    this.formUpload.get('file').setValue('');
    this.modalRef = this.modalService.show(template);
  }

  blockForm(flg: boolean) {
    let keys = Object.keys(this.formDados.controls);
    for (let i = 0; i < keys.length; i++) {
      if (keys[i] != 'cpf') {
        if (!flg) {
          this.formDados.controls[keys[i]].enable();
        } else {
          this.formDados.controls[keys[i]].disable();
        }
      }
    }

    this.block = flg;
  }

  buscaCliente() {
    this._clienteService.buscaCliente(this.cpfCliente).subscribe(
      (response) => {
        if (response.cpf == this.cpfCliente) {
          this.nomeCliente = response.nome;
          this.sobreNomeCliente = response.sobrenome;
          this.dddCliente = response.ddd;
          this.telefoneCliente = response.telefone;
          this.emailCliente = response.email;
          this.blockForm(false);
        } else {
          this.nomeCliente = '';
          this.sobreNomeCliente = '';
          this.dddCliente = '';
          this.telefoneCliente = '';
          this.emailCliente = '';
          this.blockForm(true);
          this._toastr.error('Cliente não encontrado', '');
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.formUpload.get('file').setValue(file);
    }
  }

  enviarArquivo() {
    this.loading = true;
    const formData = new FormData();
    formData.append('file', this.formUpload.get('file').value);

    this._clienteService.enviarPlanilha(formData).subscribe(
      (response) => {
        this.loading = false;
        this._toastr.success('Planilha enviada e processada', '');
        this.logEnvio = response;
        if (this.logEnvio.dados) {
          let keys = Object.keys(this.logEnvio.dados);
          let csv = 'CPF,Resultado,Msgs\r\n';
          let msg = '';
          let dado = null;
          for (let i = 0; i < keys.length; i++) {
            msg = '';
            dado = this.logEnvio.dados[keys[i]];
            if (dado.mensagens) {
              for (let j = 0; j < dado.mensagens.length; j++) {
                msg += dado.mensagens[j] + '/';
              }
            }
            csv += dado.cliente_cpf + ',' + dado.resultado + ',' + msg + '\r\n';
          }
          var blob = new Blob([csv], { type: 'text/csv' });
          saveAs(blob, 'myFile.csv');
        }
      },
      (error) => {
        this.loading = false;
        console.log(error);
        this._toastr.error('Erro ao enviar a planilha', '');
      }
    );
  }

  atualizarCliente() {
    this.loading = true;
    let cliente: Cliente;
    cliente = new Cliente();

    cliente.cpf = this.cpfCliente;
    cliente.nome = this.nomeCliente;
    cliente.sobrenome = this.sobreNomeCliente;
    cliente.ddd = this.dddCliente;
    cliente.telefone = this.telefoneCliente;
    cliente.email = this.emailCliente;

    let list: Cliente[];
    list = [cliente];

    this._clienteService.atualizarCliente(list).subscribe(
      (response) => {
        this.loading = false;

        if (response.dados[0].resultado == 'Ok') {
          this._toastr.success('Cliente atualizado com sucesso', '');
        } else {
          for (let i = 0; i < response.dados[0].mensagens.length; i++) {
            this._toastr.error(response.dados[0].mensagens[i], '');
          }
        }
      },
      (error) => {
        this.loading = false;
        console.log(error);
        if (error.status == 400) {
          this._toastr.error(error.error.message, '');
        } else if (error.status == 401) {
          localStorage.setItem('token', null);
          window.location.reload();
        } else {
          this._toastr.error('Erro ao atualizar cliente', '');
        }
      }
    );
  }
}
