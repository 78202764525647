import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageDadosClienteComponent} from './page-dados-cliente/page-dados-cliente.component'
import {PageLoginComponent} from './page-login/page-login.component'
import {PageClientesAbertoComponent} from './page-clientes-aberto/page-clientes-aberto.component'
import { PageClienteExtratoComponent } from './page-cliente-extrato/page-cliente-extrato.component';
import { PageHomeComponent } from './page-home/page-home.component';
import { AuthGuard} from './auth.guard'
import { AuthOperadorGuard } from './auth-operador.guard';
import { ErrorComponent } from './error/error.component';
const routes: Routes = [
{
  path:'dados-cliente',
  component: PageDadosClienteComponent,
  canActivate: [AuthGuard]
},{
  path:'login',
  component: PageLoginComponent
},
{
path:"cliente-extrato",
component: PageClienteExtratoComponent,
canActivate: [AuthOperadorGuard]
},
{
  path:'cliente-segmento',
  component:PageClientesAbertoComponent,
  canActivate: [AuthGuard]
},
{
  path:'',
  component:PageHomeComponent,
  canActivate: [AuthOperadorGuard]
},
  // Redirect any route not founded to home
  { path: "**", component: ErrorComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
