import { Injectable } from '@angular/core';
import { WebserviceLogin } from '../models/webservice-login';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ResponseBase } from '../models/response-base';

@Injectable({
  providedIn: 'root'
})
export class AutenticacaoService {

  constructor(private _http: HttpClient) {

  }

  login(data: WebserviceLogin): Observable<ResponseBase<string>> {
    return this._http.post<any>(environment.baseUrl + '/Login', data)
  }
}