import { Injectable } from '@angular/core';
import { WebserviceLogin } from '../models/webservice-login';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponseBaseMeusolzinho } from '../models/response-base-meusolzinho.model';
import { ResponseMeusolzinho} from '../models/response-meusolzinho.model'
import { Cliente } from '../models/cliente.model';

@Injectable({
  providedIn: 'root'
})
export class ClienteService {

  constructor(private _http: HttpClient) { }

  buscaCliente(cpf:String): Observable<Cliente>{
    let headerRequisicao = new HttpHeaders();
    headerRequisicao = headerRequisicao.set('Authorization',`Bearer ${localStorage.getItem('token')}`)    
    return this._http.get<Cliente>(environment.baseUrl + '/cliente/script/consulta-cliente/'+cpf,{headers: headerRequisicao});
  }

  atualizarCliente(data:Array<Cliente>): Observable<ResponseMeusolzinho<ResponseBaseMeusolzinho>>{
    let headerRequisicao = new HttpHeaders();
    headerRequisicao = headerRequisicao.set('Authorization',`Bearer ${localStorage.getItem('token')}`)  
    return this._http.post<ResponseMeusolzinho<ResponseBaseMeusolzinho>>(environment.baseUrl + '/cliente/script/atualizar-cliente',data,{headers: headerRequisicao});
  }

  enviarPlanilha(data:FormData): Observable<ResponseMeusolzinho<ResponseBaseMeusolzinho>>{
    let headerRequisicao = new HttpHeaders();    
    headerRequisicao = headerRequisicao.set('Authorization',`Bearer ${localStorage.getItem('token')}`)    
    return this._http.post<ResponseMeusolzinho<ResponseBaseMeusolzinho>>(environment.baseUrl + '/cliente/script/atualizar-cliente-excel',data,{headers: headerRequisicao});
  }
}
