<div class="flex height-max flex-direction-column background-color-cinza ">
    <ngx-loading [show]="loading"></ngx-loading>
    <!-- HEADER -->
    <app-header></app-header>

    <div class="flex flex-direction-row margin-container padding-padrao ">
        <span class="span span-roxo span-titulo">
            ATUALIZAR CADASTRO DE CLIENTES
        </span>
    </div>


    <!-- DIVISAO -->
    <div class="flex flex-direction-row margin-container divisao ">
    </div>

    <div class="flex flex-direction-row margin-container padding-padrao flex-justify-content-right ">
        <img style="width:56px;cursor: pointer;" src="../../assets/images/excel.png" (click)="openModal(template)" />
    </div>

    <div class="flex flex-direction-row margin-container padding-padrao flex-justify-content-center ">
        <form [formGroup]="formDados" class="width-40">
            <div class="flex flex-direction-row margin-container padding-pequetuxo">


                <span class="span span-roxo margin-separar-frente">
                    CPF
                </span>
            </div>
            <div class="flex flex-direction-row margin-container padding-pequetuxo">

                <input class="input input-roxo width-cpf margin-separar-frente" type="text" mask="000.000.000-00"
                    formControlName="cpf" [(ngModel)]="cpfCliente" placeholder="000.000.000-00"
                    (blur)="buscaCliente();">
            </div>



            <div class="flex flex-direction-row margin-container padding-pequetuxo">

                <span class="span span-roxo margin-separar-frente">
                    NOME
                </span>
            </div>
            <div class="flex flex-direction-row margin-container padding-pequetuxo">

                <input [(ngModel)]="nomeCliente" class="input input-roxo width-max margin-separar-frente" type="text"
                    formControlName="nome">

            </div>


            <div class="flex flex-direction-row margin-container padding-pequetuxo">
                <span class="span span-roxo margin-separar-frente">
                    SOBRE NOME
                </span>
            </div>
            <div class="flex flex-direction-row margin-container padding-pequetuxo">
                <input [(ngModel)]="sobreNomeCliente" class="input input-roxo width-max margin-separar-frente"
                    type="text" formControlName="sobrenome">
            </div>


            <div class="flex flex-direction-row margin-container padding-pequetuxo">
                <span class="span span-roxo margin-separar-frente">
                    DDD
                </span>
            </div>
            <div class="flex flex-direction-row margin-container padding-pequetuxo">
                <input [(ngModel)]="dddCliente" class="input input-roxo width-max margin-separar-frente" type="text"
                    formControlName="ddd">
            </div>


            <div class="flex flex-direction-row margin-container padding-pequetuxo">
                <span class="span span-roxo margin-separar-frente">
                    Telefone
                </span>
            </div>
            <div class="flex flex-direction-row margin-container padding-pequetuxo">
                <input [(ngModel)]="telefoneCliente" class="input input-roxo width-max margin-separar-frente"
                    type="text" formControlName="telefone">
            </div>


            <div class="flex flex-direction-row margin-container padding-pequetuxo">
                <span class="span span-roxo margin-separar-frente">
                    Email
                </span>
            </div>
            <div class="flex flex-direction-row margin-container padding-pequetuxo">
                <input [(ngModel)]="emailCliente" class="input input-roxo width-max margin-separar-frente" type="text"
                    formControlName="email">
            </div>
            <div class="flex flex-direction-row margin-container padding-padrao flex-justify-content-right">
                <button class="margin-separar-tras botao botao-roxo height-100 " (click)="atualizarCliente();"
                    [disabled]="block">
                    ATUALIZAR CLIENTE
                </button>
            </div>



        </form>
    </div>


</div>

<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Importar planilha ...</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">

        <div class="flex flex-direction-row  padding-padrao ">
            <form [formGroup]="formUpload" class="width-100">
                <div class="flex flex-direction-row  padding-pequetuxo">
                    <span>Por favor selecione o arquivo ( <a href="../../assets/files/layout_at_cliente.xlsx"> baixar layout </a>) para realizar a atualização dos clientes:</span>
                </div>
                <div class="flex flex-direction-row  padding-pequetuxo">
                    <input class="input input-roxo width-max" type="file" (change)="onFileChange($event)" />
                </div>

                <ng-container *ngIf="logEnvio">
                    <hr/>
                    <span>Log de importação:</span>
                    <hr/>
                    <div style="height:400px;overflow-y: auto;overflow-x: hidden;">
                        <table class="table table-striped">
                            <thead class="thead-dark">
                                <tr>
                                    <th>Cpf</th>
                                    <th>Resultado</th>
                                    <th>Msg</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let log of logEnvio.dados">
                                    <td>{{log.cliente_cpf}}</td>
                                    <td>{{log.resultado}}</td>
                                    <td>
                                        <div class="row" *ngFor="let msg of log.mensagens">
                                            {{msg}}</div>
                                    </td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                </ng-container>


                <div class="flex flex-direction-row  padding-pequetuxo flex-justify-content-right">
                    <button class="margin-separar-tras botao botao-roxo height-100 " (click)="enviarArquivo();">
                        ENVIAR
                    </button>
                </div>
            </form>
        </div>
    </div>
</ng-template>