import { Component } from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import * as moment from 'moment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'rihappy-crm-clientes-front';
  possuiToken: boolean = false;

  constructor() {
    this.possuiToken = this.checkAutentication();
    console.log(this.possuiToken);
  }

  checkAutentication() {
    let token = localStorage.getItem('token');

    if (token == null || token == undefined || token == 'null') {
      return false;
    }

    let decoded = jwt_decode(token);
    let dataAtual: Date = new Date();
    let dataExpiracao: Date = new Date(decoded.expiration_date);
    // let dataExpiracao: Date = moment(decoded.expiration_date, 'DD/MM/YYYY hh:mm:ss').toDate();


    if (dataExpiracao < dataAtual)
      return false;

    return true;
  }


}
