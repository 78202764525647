import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import * as jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class AuthOperadorGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    let home ='/login';
    let token = localStorage.getItem('token');

    if (token != null && token != undefined && token != 'null') {
      let decoded = jwt_decode(token);
      let dataAtual: Date = new Date();
      let dataExpiracao: Date = new Date(decoded.expiration_date);
      let expirado = false;
      if (dataExpiracao < dataAtual) expirado=true;

      if(!expirado) {
        return true;
      }

      if(!expirado)
        home='';
    }

    this.router.navigate([home], {
      queryParams: {
        return: state.url,
      },
    });

    return false;
  }
}
